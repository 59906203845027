import { ElementCategoryData, ElementData } from '@wix/platform-editor-sdk';
import {
  ComponentIds as DonationFormComponentIds,
  StateIds,
} from '@/components/DonationForm/constants';
import { getRole } from '@/common/utils/getRole';
import { ILocaleKeys } from '@/locale-keys';
import { ComponentIds as FrequencyWidgetComponentIds } from '@/components/FrequencyWidget/constants';
import { ComponentIds as AmountWidgetComponentIds } from '@/components/AmountWidget/constants';
import { ComponentIds as NoteWidgetComponentIds } from '@/components/NoteWidget/constants';
import { ComponentIds as GoalWidgetComponentIds } from '@/components/GoalAmountWidget/constants';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { SPECS } from '@/common/constants/specs';
import { ElementsPanelScope } from './openShowHidePanel';

export const getElementsPanelData = (
  t: ILocaleKeys,
  flowAPI: FlowAPI,
  state: StateIds,
  scope: ElementsPanelScope,
): { elementsData: ElementData[]; categoriesData: ElementCategoryData[] } => {
  const isGoalEnabled = flowAPI.experiments.enabled(SPECS.CampaignGoal);
  switch (scope) {
    case ElementsPanelScope.WIDGET:
      if (state === StateIds.Form || !isGoalEnabled) {
        return {
          elementsData: getFormStateWidgetElements(t, isGoalEnabled),
          categoriesData: [],
        };
      } else {
        // TODO: Implement other states elements
        return {
          elementsData: [],
          categoriesData: [],
        };
      }
    case ElementsPanelScope.GOAL:
      return {
        elementsData: getGoalElements(t),
        categoriesData: [],
      };
    default:
      return {
        elementsData: [],
        categoriesData: [],
      };
  }
};

const getFormStateWidgetElements = (
  t: ILocaleKeys,
  isGoalEnabled: boolean,
): ElementData[] => {
  let i = 0;
  return [
    {
      label: t.donationForm.formWidget.elements.title.label(),
      identifier: { role: getRole(DonationFormComponentIds.Title) },
      index: i++,
    },
    {
      label: t.donationForm.formWidget.elements.description.label(),
      identifier: { role: getRole(DonationFormComponentIds.Description) },
      index: i++,
    },
    ...(isGoalEnabled
      ? [
          {
            label: 'TBD - Progress Bar',
            identifier: { role: getRole(DonationFormComponentIds.GoalAmount) },
            index: i++,
          },
        ]
      : []),
    {
      label: t.donationForm.formWidget.elements.frequency.label(),
      identifier: { role: getRole(FrequencyWidgetComponentIds.Label) },
      index: i++,
    },
    {
      label: t.donationForm.formWidget.elements.amount.label(),
      identifier: { role: getRole(AmountWidgetComponentIds.Label) },
      index: i++,
    },
    {
      label: t.donationForm.formWidget.elements.donorNote.label(),
      identifier: { role: getRole(NoteWidgetComponentIds.Label) },
      index: i++,
    },
  ];
};

const getGoalElements = (t: ILocaleKeys): ElementData[] => {
  let i = 0;
  return [
    {
      label: t.donations.widget.progressBarElements.display.raisedAmount(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.DonatedAmountText),
      },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.goalAmount(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.GoalAmountText),
      },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.progressFill(),
      identifier: { role: getRole(GoalWidgetComponentIds.ProgressBar) },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.numDonations(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.DonationCountBox),
      },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.deadlineCountdown(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.DaysLeftBox),
      },
      index: i++,
    },
    {
      label:
        t.donations.widget.progressBarElements.display.percentageFromGoal(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.PercentageText),
      },
      index: i++,
    },
  ];
};
