import { ExperimentsBag } from '@wix/yoshi-flow-editor';

export const SPECS = {
  defaultCampaign: 'specs.donations.defaultCampaign',
  usePlatformCheckoutContentTranslation:
    'specs.donations.UsePlatformCheckoutContentTranslations',
  oneFrequencyOptionFormDisplay:
    'specs.donations.oneFrequencyOptionFormDisplay',
  CampaignGoal: 'specs.donations.CampaignGoal',
  CampaignGoalUoU: 'specs.donations.CampaignGoalUoU',
  OnlySpecifiedSelectable: 'specs.donations.OnlySpecifiedSelectable',
} as const;

const openedExperimentsForTests: string[] = [
  SPECS.usePlatformCheckoutContentTranslation,
  SPECS.oneFrequencyOptionFormDisplay,
  SPECS.CampaignGoal,
  SPECS.CampaignGoalUoU,
  SPECS.OnlySpecifiedSelectable,
];

const openedExperimentsForSled: string[] = [
  SPECS.oneFrequencyOptionFormDisplay,
  SPECS.CampaignGoal,
  SPECS.CampaignGoalUoU,
];

export const getOpenedExperimentsForTests = (): ExperimentsBag => {
  return openedExperimentsForTests.reduce((prv: any, cur: any) => {
    prv[cur] = 'true';
    return prv;
  }, {});
};

export const getOpenedExperimentsForSled = () => {
  return openedExperimentsForSled.reduce((obj, ex) => {
    obj[ex] = true;
    return obj;
  }, {});
};
