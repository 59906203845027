import { ComponentRef, EditorSDK, EventType } from '@wix/platform-editor-sdk';
import { initLocaleKeys } from '@/common/utils/locale';
import { getElementsPanelData } from './getElementsPanelData';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { showHideDonationsWidgetElements } from '@wix/bi-logger-wixdonations/v2';
import { StateIds } from '@/components/DonationForm/constants';
import { getCollapsedRefComponentByRole, getWidgetCompRef } from './refUtils';

export const enum ElementsPanelScope {
  WIDGET = 'WIDGET',
  GOAL = 'GOAL',
}

export const openShowHidePanel = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  flowAPI: FlowAPI,
  scope: ElementsPanelScope,
): Promise<void> => {
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const onAddComponentByRole = async ({ role }) => {
    const componentRef = await getCollapsedRefComponentByRole({
      editorSDK,
      widgetRef,
      role,
    });
    return showCompByRef(componentRef);
  };

  const showCompByRef = async (componentRef) => {
    flowAPI.bi?.report(
      showHideDonationsWidgetElements({
        element: componentRef.role,
        show: false,
      }),
    );
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
  };

  const onRemoveComponentByRole = async (componentRef) => {
    const compToHide = await getWidgetCompRef({ editorSDK, componentRef });
    return hideCompByRef(compToHide);
  };

  const hideCompByRef = async (componentRef) => {
    flowAPI.bi?.report(
      showHideDonationsWidgetElements({
        element: componentRef.role,
        show: false,
      }),
    );
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  const widgetState = (await editorSDK.editor.widgets.getViewState('token', {
    componentRef: widgetRef,
  })) as StateIds;

  const { elementsData, categoriesData } = getElementsPanelData(
    t,
    flowAPI,
    widgetState,
    scope,
  );

  updatePanelContentOnViewStateChanged(editorSDK, widgetRef, flowAPI, scope);

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    elementsData,
    categoriesData,
    addComponentHandler: onAddComponentByRole,
    removeComponentHandler: onRemoveComponentByRole,
  });
};

const updatePanelContentOnViewStateChanged = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  flowAPI: FlowAPI,
  scope: ElementsPanelScope,
) => {
  editorSDK.addEventListener(
    EventType.viewStateChanged,
    () => {
      openShowHidePanel(editorSDK, widgetRef, flowAPI, scope);
    },
    { once: true },
  );
};
