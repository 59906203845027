import { EcomComponent, withEcomPlatformData } from '@wix/ecom-platform-sdk';
import { getAppManifest as createAppManifest } from './editor-app/manifest/manifest';
import {
  DONATIONS_APP_DEF_ID,
  MEMBERS_AREA_DEFAULT_APPS,
} from '@/common/constants/app';
import { EditorSDK, ExportsFn } from '@wix/platform-editor-sdk';
import type { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { firstInstallHandler } from '@/editor-app/editor-ready/first-install/firstInstallHandler';
import { registerGfppEvents } from '@/editor-app/editor-ready/gfppEvents/gfppEvents';
import { initLocaleKeys } from '@/common/utils/locale';
import {
  registerDashboardClosedEvent,
  registerWidgetResetEvent,
} from '@/editor-app/editor-ready/dashboardEvents';

let t: ReturnType<typeof initLocaleKeys> | undefined;

const exportsImpl: ExportsFn = (editorSDK: EditorSDK, { biData }) => ({
  editor: {
    appInstalled: async (payload) => {
      if (payload.appDefinitionId === DONATIONS_APP_DEF_ID) {
        await editorSDK.editor.updateProgressBar('_token', {
          currentStep: 3,
          stepTitle: t
            ? t.wixDonations.installationPopup.loader.done()
            : 'Wix Donations is now installed on your site',
        });
        await editorSDK.editor.closeProgressBar('_token', {});

        const pageRef = await editorSDK.document.pages.getHomePage('');
        await editorSDK.pages.navigateTo('', { pageRef });
      }
    },
  },
});

const editorReadyImpl: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  t = initLocaleKeys(flowAPI.translations.i18n);
  if (options.firstInstall) {
    await firstInstallHandler(editorSDK, t, options, flowAPI as any);
  }

  await Promise.all([
    registerDashboardClosedEvent(editorSDK),
    registerWidgetResetEvent(editorSDK),
    registerGfppEvents(editorSDK, options.origin.type, flowAPI, options),
  ]);
};

const platformApp = withEcomPlatformData(
  {
    editorReady: editorReadyImpl,
    getAppManifest: createAppManifest,
    exports: exportsImpl,
  } as any,
  {
    ecomComponents: {
      [EcomComponent.CHECKOUT]: {
        isInstalled: true,
      },
    },
    managingAppId: DONATIONS_APP_DEF_ID,
  },
  {
    installAutomatically: true,
    installAppsAutomatically: true,

    membersAreaApps: MEMBERS_AREA_DEFAULT_APPS,
  },
);

export const editorReady = platformApp.editorReady;
export const onEvent = (
  { eventType, eventPayload }: any,
  editorSDK: EditorSDK,
) => {
  if (eventType === 'appActionClicked') {
    const { actionId } = eventPayload;
    switch (actionId) {
      case 'DONATION_ADD_PANEL_DEEPLINK':
        editorSDK.editor.deeplink.show('', {
          type: 'addPanel',
          params: [DONATIONS_APP_DEF_ID],
        });
        break;
    }
  }
};

export const getAppManifest = platformApp.getAppManifest;
export const exports = platformApp.exports;
