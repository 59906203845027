import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { initLocaleKeys } from '@/common/utils/locale';
import { helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/AmountWidget/constants';
import {
  setAllComponentsAsUnselectable,
  setComponentsAsUnselectable,
} from '@/editor-app/manifest/utils/setAsUnselectable';
import { getRole } from '@/common/utils/getRole';
import {
  setSelectableContainerGfpp,
  setSelectableTextGfpp,
} from '@/editor-app/manifest/utils/SelectableRepeater';
import { setInputFieldGfpp } from '@/editor-app/manifest/utils/InputField';
import { SPECS } from '@/common/constants/specs';
import amoutWidget from './.component.json';
import { setAsSelectable } from '@/editor-app/manifest/utils/setAsSelectable';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const isOnlySpecifiedSelectable = flowAPI.experiments.enabled(
    SPECS.OnlySpecifiedSelectable,
  );
  if (isOnlySpecifiedSelectable) {
    setAllComponentsAsUnselectable(widgetBuilder, amoutWidget.id);
  }

  widgetBuilder.set({
    displayName: t.donationForm.amount.displayName(),
    nickname: 'AmountWidgetWidget',
  });

  widgetBuilder.behavior().set({ removable: false });

  widgetBuilder
    .gfpp()
    .set('add', { behavior: 'HIDE' })
    .set('help', { id: helpIds.AMOUNT })
    .set('animation', { behavior: 'HIDE' });

  widgetBuilder
    .configureConnectedComponents(
      getRole(ComponentIds.Label),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountTitle.displayName(),
        });
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder, { canBeDeleted: true });
        }
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountBox),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountDefaultBtn),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemTextDefault),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountText.regular.displayName(),
        });
        innerWidgetBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY', preventHide: true });
        setSelectableTextGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.amountText.regular.gfpp.design(),
        );
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        }
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemTextSelected),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountText.selected.displayName(),
        });
        innerWidgetBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY', preventHide: true });
        setSelectableTextGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.amountText.selected.gfpp.design(),
        );
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        }
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemContainerDefault),
      (innerWidgetBuilder) => {
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        } else {
          innerWidgetBuilder.behavior().set({
            closed: {
              selectable: true,
            },
            preventHide: true,
          });
        }
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountOption.regular.displayName(),
        });
        setSelectableContainerGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.gfpp.learnMore(),
          helpIds.AMOUNT,
        );
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        }
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemContainerSelected),
      (innerWidgetBuilder) => {
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        } else {
          innerWidgetBuilder.behavior().set({
            closed: {
              selectable: true,
            },
            preventHide: true,
          });
        }
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountOption.selected.displayName(),
        });
        setSelectableContainerGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.gfpp.learnMore(),
          helpIds.AMOUNT,
        );
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountFieldInput),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.otherAmountField.displayName(),
        });
        setInputFieldGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.gfpp.learnMore(),
          helpIds.AMOUNT,
        );
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder, { canBeDeleted: false });
        }
      },
    );

  if (!isOnlySpecifiedSelectable) {
    setComponentsAsUnselectable(widgetBuilder, [
      ComponentIds.Repeater,
      ComponentIds.ListBox,
      ComponentIds.multiStateItem,
      ComponentIds.RepeaterItem,
      ComponentIds.RepeaterItemInput,
      ComponentIds.CustomAmountField,
      ComponentIds.itemContainerSelectedState,
      ComponentIds.itemContainerDefaultState,
    ]);
  }
};
