export enum ComponentIds {
  DonateButton = '#donateButton',
  Amount = '#amountWidget1',
  Frequency = '#frequencyWidget',
  GoalAmount = '#goalAmountWidget1',
  Comment = '#noteWidget',
  Title = '#title',
  Description = '#description',
  EmptyStateBox = '#emptyState',
  EmptyStateTitle = '#text1',
  EmptyStateBody = '#text2',
  EmptyStateFrequencyTitle = '#emptyStateFrequncy',
  EmptyStateAmountTitle = '#text3',
  EmptyStateNoteTitle = '#text4',
  EmptyStateDonateButton = '#button1',
  ExpiredStateTitle = '#text10',
  ExpiredStateBody = '#text9',
  ExpiredStateButton = '#button3',
  ExpiredStateGoalWidget = '#goalAmountWidget3',
  ExpiredStateButtonBox = '#box25',
  GoalReachedTitle = '#text7',
  GoalReachedBody = '#text8',
  GoalReachedButton = '#button2',
  GoalReachedGoalWidget = '#goalAmountWidget2',
  GoalReachedButtonBox = '#box24',
  FormStateBox = '#formState',
  MultiStateBox = '#multiStateBox1',
  RootBox = '#box1',
  PremiumErrorIcon = '#errorIcon',
  PremiumError = '#errorBox',
  PremiumErrorText = '#errorText',
  PremiumErrorContainer = '#errorInnerBox',
  DonateButtonContainer = '#box3',
}

export const EmptyStateComponentIds = [
  '#button1',
  '#box16',
  '#box15',
  '#text4',
  '#box14',
  '#box12',
  '#text3',
  '#box10',
  '#repeater1',
  '#selectableContainer1',
  '#box9',
  '#selectableContainerInput1',
  '#box8',
  '#emptyStateFrequncy',
  '#emptystateRepeater',
  '#presetsRepeater',
  '#box7',
  '#selectableContainer',
  '#selectableContainerInput',
  '#text2',
  '#text1',
];

export enum StateIds {
  Form = 'formState',
  Empty = 'emptyState',
  GoalNotReached = 'goalNotReachedState',
  GoalReached = 'goalReachedState',
}
