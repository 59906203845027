import { ActionIds, helpIds } from '@/common/constants/gfpp';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { openManageCampaignsDashboard } from '../utils/openManageCampaignsDashboard';
import { clickManageCampaigns } from '@wix/bi-logger-wixdonations/v2';
import { PanelNames } from '@/common/constants/panels';
import { AppManifestParams } from '../manifest';

export const setDonationFormGFPPs = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys, editorSDK, flowAPI, panelsApi, revision }: AppManifestParams,
) => {
  formWidgetBuilder
    .set({
      displayName: localeKeys.donationForm.formWidget.displayName(),
      nickname: 'DonationFormWidget',
    })
    .gfpp()
    .set('mainAction1', {
      label: localeKeys.donationForm.formWidget.gfpp.chooseCampaign(),
      onClick: (event) => {
        const { componentRef } = event.detail;
        void panelsApi.openBlocksPanel(
          PanelNames.chooseCampaign,
          componentRef as any,
          {
            overrides: {
              revision,
              title:
                localeKeys.donationForm.formWidget.chooseCampaign.panelHeader(),
            },
          },
        );
      },
    })
    .set('mainAction2', {
      label: localeKeys.donationForm.formWidget.gfpp.manageCampaigns(),
      onClick: () => {
        flowAPI.bi!.report(
          clickManageCampaigns({
            origin: 'gfpp',
          }),
        );
        return openManageCampaignsDashboard(editorSDK);
      },
    })
    .set('help', { id: helpIds.DONATION_FORM })
    .set('add', {
      actionId: ActionIds.DONATION_FORM_ELEMENTS,
    })
    .set('layout', { actionId: ActionIds.DONATION_FORM_LAYOUT })
    .set('settings', { actionId: ActionIds.DONATION_FORM_SETTINGS })
    .set('animation', { behavior: 'HIDE' })
    .set('stretch', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });

  formWidgetBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: localeKeys.donationForm.formWidget.gfpp.chooseCampaign(),
      onClick: (event) => {
        const { componentRef } = event.detail;
        void panelsApi.openBlocksPanel(
          PanelNames.chooseCampaign,
          componentRef as any,
          {
            overrides: {
              revision,
              title:
                localeKeys.donationForm.formWidget.chooseCampaign.panelHeader(),
            },
          },
        );
      },
    })
    .set('mainAction2', {
      label: localeKeys.donationForm.formWidget.gfpp.manageCampaigns(),
      onClick: () => {
        flowAPI.bi!.report(
          clickManageCampaigns({
            origin: 'gfpp',
          }),
        );
        return openManageCampaignsDashboard(editorSDK);
      },
    })
    .set('help', { id: helpIds.DONATION_FORM })
    .set('layout', { actionId: ActionIds.DONATION_FORM_LAYOUT })
    .set('settings', { actionId: ActionIds.DONATION_FORM_SETTINGS })
    .set('animation', { behavior: 'HIDE' });
};
