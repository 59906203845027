import { helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/DonationForm/constants';
import {
  ConnectedComponentsBuilder,
  WidgetBuilder,
} from '@wix/app-manifest-builder';
import { getRole } from '../../utils/getRole';
import { AppManifestParams } from '../../manifest';
import { setAsSelectable } from '../../utils/setAsSelectable';
import { SPECS } from '@/common/constants/specs';

export const setFormButtonComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys, editorSDK, flowAPI }: AppManifestParams,
) => {
  formWidgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.DonateButton),
    (componentBuilder: ConnectedComponentsBuilder) => {
      componentBuilder.behavior().set({ preventHide: true });
      componentBuilder
        .set({
          displayName: localeKeys.donationForm.donateButton.displayName(),
        })
        .gfpp()
        .set('mainAction2', {
          label: localeKeys.donationForm.donateButton.gfpp.design(),
          onClick: ({ detail: { componentRef } }) => {
            void editorSDK.editor.openNativeComponentPanel('', 'design', {
              componentRef,
            });
          },
        })
        .set('add', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('help', { id: helpIds.DONATE_BUTTON })
        .set('animation', { behavior: 'HIDE' })
        .set('layout', { behavior: 'HIDE' });
      componentBuilder.panel<'StylableButton'>('settings').configureControls({
        link: {
          hidden: true,
        },
        icon: {
          hidden: true,
        },
      } as any); // https://jira.wixpress.com/browse/ECL-5987
      if (flowAPI.experiments.enabled(SPECS.OnlySpecifiedSelectable)) {
        setAsSelectable(componentBuilder, { canBeDeleted: false });
      }
    },
  );
};

export const setExpiredButtonComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) =>
  setEditableButtonComponent(
    formWidgetBuilder,
    params,
    ComponentIds.ExpiredStateButton,
  );

export const setGoalReachedButtonComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) =>
  setEditableButtonComponent(
    formWidgetBuilder,
    params,
    ComponentIds.GoalReachedButton,
  );

const setEditableButtonComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys }: AppManifestParams,
  id: string,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(id),
    (componentBuilder: ConnectedComponentsBuilder) => {
      componentBuilder.set({
        displayName:
          localeKeys.donations.widget.progressBar.button.displayName(),
      });
      setAsSelectable(componentBuilder, { canBeDeleted: false });
      componentBuilder
        .gfpp()
        .set('add', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' })
        .set('animation', { behavior: 'HIDE' });
    },
  );
