import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { setAllComponentsAsUnselectable } from '@/editor-app/manifest/utils/setAsUnselectable';
import goalAmountWidget from './.component.json';
import { ComponentIds } from './constants';
import { setAsSelectable } from '@/editor-app/manifest/utils/setAsSelectable';
import { initLocaleKeys } from '@/common/utils/locale';
import {
  ConnectedComponentsBuilder,
  WidgetBuilder,
} from '@wix/app-manifest-builder';
import { getRole } from '@/common/utils/getRole';
import { ILocaleKeys } from '@/locale-keys';
import { hideSeoA11y } from '@/editor-app/manifest/utils/hideSeoA11y';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const localeKeys = initLocaleKeys(flowAPI.translations.i18n);

  widgetBuilder.set({
    displayName: 'Widget GoalAmountWidget',
    nickname: 'GoalAmountWidgetWidget',
  });
  setAllComponentsAsUnselectable(widgetBuilder, goalAmountWidget.id);
  setAllDesignOnlyTextComponents(widgetBuilder, localeKeys);
  setProgressBarComponent(widgetBuilder, localeKeys);
};

const setAllDesignOnlyTextComponents = (
  widgetBuilder: WidgetBuilder,
  localeKeys: ILocaleKeys,
) => {
  const designOnlyTextComponents: Record<string, { displayName: string }> = {
    [ComponentIds.DonatedAmountText]: {
      displayName:
        localeKeys.donations.widget.progressBar.amountRaised.displayName(),
    },
    [ComponentIds.GoalAmountText]: {
      displayName:
        localeKeys.donations.widget.progressBar.goalAmount.displayName(),
    },
    [ComponentIds.DonationCountText]: {
      displayName:
        localeKeys.donations.widget.progressBar.numDonations.displayName(),
    },
    [ComponentIds.TimeLeftText]: {
      displayName:
        localeKeys.donations.widget.progressBar.deadlineCountdown.displayName(),
    },
    [ComponentIds.PercentageText]: {
      displayName:
        localeKeys.donations.widget.progressBar.percentageFromGoal.displayName(),
    },
  };

  Object.entries(designOnlyTextComponents).forEach(
    ([componentId, { displayName }]) => {
      widgetBuilder.configureConnectedComponents(
        getRole(componentId),
        (componentBuilder) => {
          setDesignOnlyTextComponent(componentBuilder, displayName);
        },
      );
    },
  );
};

const setProgressBarComponent = (
  widgetBuilder: WidgetBuilder,
  localeKeys: ILocaleKeys,
) => {
  widgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.ProgressBar),
    (componentBuilder) => {
      setAsSelectable(componentBuilder, { canBeDeleted: true });
      componentBuilder.set({
        displayName: localeKeys.donations.widget.progressBar.displayName(),
      });
      componentBuilder
        .gfpp()
        .set('connect', { behavior: 'HIDE' })
        .set('design', { behavior: 'DEFAULT' });
    },
  );
};

const setDesignOnlyTextComponent = (
  componentBuilder: ConnectedComponentsBuilder,
  displayName: string,
) => {
  setAsSelectable(componentBuilder, { canBeDeleted: true });
  hideSeoA11y(componentBuilder);
  componentBuilder.behavior().set({
    dataEditOptions: 'TEXT_STYLE_ONLY',
  });
  componentBuilder.set({
    displayName,
  });
  componentBuilder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });
};
