import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

export const getParentWidgetProps = async ({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) => {
  const parentRef = await getParentWidgetRef({
    editorSDK,
    componentRef,
    skipCurrentWidget: true,
  });
  return editorSDK.application.appStudioWidgets.props.get('token', {
    widgetRef: parentRef,
  });
};

export const getParentWidgetRef = async ({
  editorSDK,
  componentRef,
  skipCurrentWidget = false,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  skipCurrentWidget?: boolean;
}) => {
  const type = await editorSDK.components.getType('token', { componentRef });
  const isWidget = type.includes('AppWidget');
  if (!isWidget || skipCurrentWidget) {
    const [parentRef] = await editorSDK.components.getAncestors('token', {
      componentRef,
    });
    return (
      parentRef &&
      getParentWidgetRef({
        editorSDK,
        componentRef: parentRef,
        skipCurrentWidget: !isWidget && skipCurrentWidget,
      })
    );
  } else {
    return componentRef;
  }
};

export const getCollapsedRefComponentByRole = async ({
  editorSDK,
  widgetRef,
  role,
}: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  role: string;
}) => {
  const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
    componentRef: widgetRef,
  });
  const collapsedRefComponents =
    await editorSDK.components.refComponents.getCollapsedRefComponents(
      'token',
      {
        componentRef: widgetRefHost,
        // @ts-expect-error temp until types are GAed
        includeInnerCollapsed: true,
      },
    );
  const collapsedRefComponent = collapsedRefComponents.filter(
    (comp) => comp.role === role,
  );
  return collapsedRefComponent[0].componentRef;
};

export const getWidgetCompRef = async ({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) => {
  const type = await editorSDK.components.getType('token', { componentRef });
  return type.includes('AppWidget')
    ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
    : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
};
