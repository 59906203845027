/* eslint-disable */
/* tslint:disable */
export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return {
    widget: {
      frequencies: {
        label: () => t('widget.frequencies.label'), /* Frequencies */
        oneTime: {
          label: () => t('widget.frequencies.oneTime.label'), /* One Time */
        },
        weekly: {
          label: () => t('widget.frequencies.weekly.label'), /* Weekly */
        },
        monthly: {
          label: () => t('widget.frequencies.monthly.label'), /* Monthly */
        },
        yearly: {
          label: () => t('widget.frequencies.yearly.label'), /* Yearly */
        },
      },
      suggestedAmounts: {
        label: () => t('widget.suggestedAmounts.label'), /* I want to give */
      },
      donate: {
        button: () => t('widget.donate.button'), /* Donate */
      },
    },
    editor: {
      donationForm: {
        label: () => t('editor.donationForm.label'), /* Donation Form */
      },
      gfpp: {
        chooseCampaign: {
          label: () => t('editor.gfpp.chooseCampaign.label'), /* Choose Campaign */
        },
        manageCampaigns: {
          label: () => t('editor.gfpp.manageCampaigns.label'), /* Manage Campaigns */
        },
      },
    },
    settings: {
      label: () => t('settings.label'), /* Configure Widget */
    },
    donationForm: {
      frequency: {
        label: {
          defaultText: () => t('donationForm.frequency.label.defaultText'), /* Frequency */
        },
        weekly: {
          option: () => t('donationForm.frequency.weekly.option'), /* Weekly */
        },
        oneTime: {
          option: () => t('donationForm.frequency.oneTime.option'), /* One time */
        },
        yearly: {
          option: () => t('donationForm.frequency.yearly.option'), /* Yearly */
        },
        monthly: {
          option: () => t('donationForm.frequency.monthly.option'), /* Monthly */
        },
        displayName: () => t('donationForm.frequency.displayName'), /* Donation Frequency */
        settings: {
          showTitle: {
            toggle: {
              label: () => t('donationForm.frequency.settings.showTitle.toggle.label'), /* Show title */
            },
          },
          panelHeader: () => t('donationForm.frequency.settings.panelHeader'), /* Frequency Settings */
          titleText: {
            label: () => t('donationForm.frequency.settings.titleText.label'), /* Title text */
          },
          default: {
            weekly: {
              option: () => t('donationForm.frequency.settings.default.weekly.option'), /* Weekly */
            },
            oneTime: {
              option: () => t('donationForm.frequency.settings.default.oneTime.option'), /* One time */
            },
            tooltip: () => t('donationForm.frequency.settings.default.tooltip'), /* This is the frequency that will be selected by default, but donors are still able to choose their own frequency. */
            monthly: {
              option: () => t('donationForm.frequency.settings.default.monthly.option'), /* Monthly */
            },
            yearly: {
              option: () => t('donationForm.frequency.settings.default.yearly.option'), /* Yearly */
            },
            label: () => t('donationForm.frequency.settings.default.label'), /* Default frequency */
            placeholder: () => t('donationForm.frequency.settings.default.placeholder'), /* Select */
          },
        },
        gfpp: {
          settings: () => t('donationForm.frequency.gfpp.settings'), /* Settings */
        },
        design: {
          panelHeader: () => t('donationForm.frequency.design.panelHeader'), /* Frequency Design */
          title: {
            panelHeader: () => t('donationForm.frequency.design.title.panelHeader'), /* Frequency Title */
            sectionName: () => t('donationForm.frequency.design.title.sectionName'), /* Title */
          },
          buttons: {
            panelHeader: () => t('donationForm.frequency.design.buttons.panelHeader'), /* Frequency Buttons */
            sectionName: () => t('donationForm.frequency.design.buttons.sectionName'), /* Buttons */
          },
        },
        title: {
          displayName: () => t('donationForm.frequency.title.displayName'), /* Title */
        },
        buttons: {
          displayName: () => t('donationForm.frequency.buttons.displayName'), /* Buttons */
        },
      },
      otherAmount: {
        minLimit: {
          errorMessage: (data: Record<'minAmount', unknown>) => t('donationForm.otherAmount.minLimit.errorMessage', data), /* We only take donations from {minAmount}. */
        },
        empty: {
          errorMessage: () => t('donationForm.otherAmount.empty.errorMessage'), /* Enter the amount you'd like to donate. */
        },
        maxLimit: {
          errorMessage: (data: Record<'maxAmount', unknown>) => t('donationForm.otherAmount.maxLimit.errorMessage', data), /* You can donate up to {maxAmount}. */
        },
        button: {
          defaultText: () => t('donationForm.otherAmount.button.defaultText'), /* Other */
        },
        input: {
          placeholder: () => t('donationForm.otherAmount.input.placeholder'), /* Enter amount */
        },
        decimalLimit: {
          errorMessage: () => t('donationForm.otherAmount.decimalLimit.errorMessage'), /* You can enter up to 2 digits after the decimal. */
        },
        specialCharacters: {
          errorMessage: () => t('donationForm.otherAmount.specialCharacters.errorMessage'), /* You can only enter digits or decimals. */
        },
      },
      description: {
        defaultText: () => t('donationForm.description.defaultText'), /* This is your campaign description. It’s a great place to tell visitors what this campaign is about, connect with your donors and draw attention to your cause. */
        displayName: () => t('donationForm.description.displayName'), /* Form Description */
      },
      emptyState: {
        noneSelected: {
          body: () => t('donationForm.emptyState.noneSelected.body'), /* Start collecting donations by connecting this form to one of your donation campaigns. Click the form and then click Choose Campaign to select the campaign you’d like to use. */
          title: () => t('donationForm.emptyState.noneSelected.title'), /* Donation form title */
        },
        archived: {
          title: () => t('donationForm.emptyState.archived.title'), /* This campaign was archived */
          body: () => t('donationForm.emptyState.archived.body'), /* Select another campaign to display or remove this widget from your site. */
        },
      },
      note: {
        optional: {
          label: () => t('donationForm.note.optional.label'), /* (optional) */
        },
        title: {
          defaultText: () => t('donationForm.note.title.defaultText'), /* Comment (optional) */
        },
      },
      donate: {
        button: {
          defaultText: () => t('donationForm.donate.button.defaultText'), /* Donate */
          notPremium: {
            errorMessage: () => t('donationForm.donate.button.notPremium.errorMessage'), /* This website can't accept donations right now. Contact the site owner. */
            editorNotification: () => t('donationForm.donate.button.notPremium.editorNotification'), /* This site can't accept donations right now. Upgrade your plan to start collecting donations. */
          },
          previewNotification: () => t('donationForm.donate.button.previewNotification'), /* To see the full checkout process, go to your published site. */
        },
      },
      amount: {
        label: {
          defaultText: () => t('donationForm.amount.label.defaultText'), /* Amount */
        },
        displayName: () => t('donationForm.amount.displayName'), /* Donation Amount */
        gfpp: {
          settings: () => t('donationForm.amount.gfpp.settings'), /* Settings */
        },
        settings: {
          showTitle: {
            toggle: {
              label: () => t('donationForm.amount.settings.showTitle.toggle.label'), /* Show title */
            },
          },
          panelHeader: () => t('donationForm.amount.settings.panelHeader'), /* Amount Settings */
          titleText: {
            label: () => t('donationForm.amount.settings.titleText.label'), /* Title text */
          },
          default: {
            tooltip: () => t('donationForm.amount.settings.default.tooltip'), /* This is the amount that will be selected by default, but donors are still able to choose a different amount. */
            placeholder: () => t('donationForm.amount.settings.default.placeholder'), /* Select */
            label: () => t('donationForm.amount.settings.default.label'), /* Default amount */
          },
        },
        design: {
          panelHeader: () => t('donationForm.amount.design.panelHeader'), /* Amount Design */
          buttons: {
            panelHeader: () => t('donationForm.amount.design.buttons.panelHeader'), /* Amount Buttons */
            sectionName: () => t('donationForm.amount.design.buttons.sectionName'), /* Buttons */
          },
          title: {
            panelHeader: () => t('donationForm.amount.design.title.panelHeader'), /* Amount Title */
            sectionName: () => t('donationForm.amount.design.title.sectionName'), /* Title */
          },
          inputField: {
            panelHeader: () => t('donationForm.amount.design.inputField.panelHeader'), /* Amount Input Field */
            sectionName: () => t('donationForm.amount.design.inputField.sectionName'), /* Input Field */
          },
        },
        title: {
          displayName: () => t('donationForm.amount.title.displayName'), /* Title */
        },
        inputField: {
          displayName: () => t('donationForm.amount.inputField.displayName'), /* Input Field */
        },
        buttons: {
          displayName: () => t('donationForm.amount.buttons.displayName'), /* Buttons */
        },
      },
      title: {
        defaultText: () => t('donationForm.title.defaultText'), /* Make a donation */
        displayName: () => t('donationForm.title.displayName'), /* Form Title */
      },
      sr: {
        otherAmount: {
          label: (data: Record<'currency', unknown>) => t('donationForm.sr.otherAmount.label', data), /* Donation amount in {currency} */
        },
        formWidget: {
          label: () => t('donationForm.sr.formWidget.label'), /* Donation form */
        },
      },
      formWidget: {
        chooseCampaign: {
          addCampaign: {
            button: () => t('donationForm.formWidget.chooseCampaign.addCampaign.button'), /* Add New Campaign */
          },
          panelHeader: () => t('donationForm.formWidget.chooseCampaign.panelHeader'), /* Choose Campaign */
          manageCampaigns: {
            button: () => t('donationForm.formWidget.chooseCampaign.manageCampaigns.button'), /* Manage Campaigns */
          },
          dropdown: {
            label: () => t('donationForm.formWidget.chooseCampaign.dropdown.label'), /* Campaign */
            placeholder: () => t('donationForm.formWidget.chooseCampaign.dropdown.placeholder'), /* Select */
          },
        },
        design: {
          background: {
            panelHeader: () => t('donationForm.formWidget.design.background.panelHeader'), /* Background Design */
            sectionName: () => t('donationForm.formWidget.design.background.sectionName'), /* Background */
          },
          panelHeader: () => t('donationForm.formWidget.design.panelHeader'), /* Donation Form Design */
          selectionButtons: {
            tooltip: () => t('donationForm.formWidget.design.selectionButtons.tooltip'), /* This will change the frequency and amount buttons. */
            sectionName: () => t('donationForm.formWidget.design.selectionButtons.sectionName'), /* Selection Buttons */
            panelHeader: () => t('donationForm.formWidget.design.selectionButtons.panelHeader'), /* Selection Button Design */
          },
          description: {
            panelHeader: () => t('donationForm.formWidget.design.description.panelHeader'), /* Description Design */
            sectionName: () => t('donationForm.formWidget.design.description.sectionName'), /* Description */
          },
          inputFields: {
            sectionName: () => t('donationForm.formWidget.design.inputFields.sectionName'), /* Input Fields */
            panelHeader: () => t('donationForm.formWidget.design.inputFields.panelHeader'), /* Input Field Design */
          },
          donateButton: {
            panelHeader: () => t('donationForm.formWidget.design.donateButton.panelHeader'), /* Donate Button Design */
            sectionName: () => t('donationForm.formWidget.design.donateButton.sectionName'), /* Donate Button */
          },
          title: {
            sectionName: () => t('donationForm.formWidget.design.title.sectionName'), /* Title */
            panelHeader: () => t('donationForm.formWidget.design.title.panelHeader'), /* Title Design */
          },
          fieldTitles: {
            sectionName: () => t('donationForm.formWidget.design.fieldTitles.sectionName'), /* Field Titles */
          },
        },
        elements: {
          title: {
            label: () => t('donationForm.formWidget.elements.title.label'), /* Title */
          },
          donorNote: {
            label: () => t('donationForm.formWidget.elements.donorNote.label'), /* Donor note title */
          },
          description: {
            label: () => t('donationForm.formWidget.elements.description.label'), /* Description */
          },
          amount: {
            label: () => t('donationForm.formWidget.elements.amount.label'), /* Amount title */
          },
          frequency: {
            label: () => t('donationForm.formWidget.elements.frequency.label'), /* Frequency title */
          },
        },
        displayName: () => t('donationForm.formWidget.displayName'), /* Donation Form */
        gfpp: {
          chooseCampaign: () => t('donationForm.formWidget.gfpp.chooseCampaign'), /* Choose Campaign */
          manageCampaigns: () => t('donationForm.formWidget.gfpp.manageCampaigns'), /* Manage Campaigns */
        },
        settings: {
          defaultFrquency: {
            tooltip: {
              text: () => t('donationForm.formWidget.settings.defaultFrquency.tooltip.text'), /* This frequency will be selected by default, but donors can also choose one of your other options. */
            },
            placeholder: () => t('donationForm.formWidget.settings.defaultFrquency.placeholder'), /* Select */
            label: () => t('donationForm.formWidget.settings.defaultFrquency.label'), /* Default selected frequency */
          },
          defaultAmount: {
            placeholder: () => t('donationForm.formWidget.settings.defaultAmount.placeholder'), /* Select */
            label: () => t('donationForm.formWidget.settings.defaultAmount.label'), /* Default selected amount */
            tooltip: {
              text: () => t('donationForm.formWidget.settings.defaultAmount.tooltip.text'), /* This amount will be selected by default, but donors can also choose one of your other options. */
            },
          },
          defaultFrequency: {
            monthly: {
              option: () => t('donationForm.formWidget.settings.defaultFrequency.monthly.option'), /* Monthly */
            },
            yearly: {
              option: () => t('donationForm.formWidget.settings.defaultFrequency.yearly.option'), /* Yearly */
            },
            weekly: {
              option: () => t('donationForm.formWidget.settings.defaultFrequency.weekly.option'), /* Weekly */
            },
            oneTime: {
              option: () => t('donationForm.formWidget.settings.defaultFrequency.oneTime.option'), /* One time */
            },
          },
          frequency: {
            header: () => t('donationForm.formWidget.settings.frequency.header'), /* Frequency Settings */
          },
          amount: {
            header: () => t('donationForm.formWidget.settings.amount.header'), /* Amount Settings */
          },
          panelHeader: () => t('donationForm.formWidget.settings.panelHeader'), /* Donation Form Settings */
          chooseCampaign: {
            note: () => t('donationForm.formWidget.settings.chooseCampaign.note'), /* To manage these settings, first choose the campaign you'd like to use for this donation form. */
          },
        },
        padding: {
          label: () => t('donationForm.formWidget.padding.label'), /* Padding */
        },
        layout: {
          panelHeader: () => t('donationForm.formWidget.layout.panelHeader'), /* Donation Form Layout */
        },
        direction: {
          rtl: {
            option: () => t('donationForm.formWidget.direction.rtl.option'), /* Right to left */
          },
          ltr: {
            option: () => t('donationForm.formWidget.direction.ltr.option'), /* Left to right */
          },
          label: () => t('donationForm.formWidget.direction.label'), /* Text direction */
          tooltip: {
            text: () => t('donationForm.formWidget.direction.tooltip.text'), /* Choose the right to left direction if your site is in a language like Arabic or Hebrew. If you have a  multilingual site, this will change the direction for all of your languages. */
          },
        },
      },
      donateButton: {
        displayName: () => t('donationForm.donateButton.displayName'), /* Donate Button */
        design: {
          panelHeader: () => t('donationForm.donateButton.design.panelHeader'), /* Donate Button Design */
        },
        gfpp: {
          settings: () => t('donationForm.donateButton.gfpp.settings'), /* Settings */
          design: () => t('donationForm.donateButton.gfpp.design'), /* Design Button */
        },
        settings: {
          panelHeader: () => t('donationForm.donateButton.settings.panelHeader'), /* Donate Button Settings */
          buttonText: {
            label: () => t('donationForm.donateButton.settings.buttonText.label'), /* Donate button text */
          },
        },
      },
      donorNote: {
        design: {
          panelHeader: () => t('donationForm.donorNote.design.panelHeader'), /* Donor Note Design */
          inputField: {
            panelHeader: () => t('donationForm.donorNote.design.inputField.panelHeader'), /* Donor Note Input */
            sectionName: () => t('donationForm.donorNote.design.inputField.sectionName'), /* Input Field */
          },
          title: {
            sectionName: () => t('donationForm.donorNote.design.title.sectionName'), /* Title */
            panelHeader: () => t('donationForm.donorNote.design.title.panelHeader'), /* Donor Note Title */
          },
        },
        settings: {
          titleText: {
            label: () => t('donationForm.donorNote.settings.titleText.label'), /* Title text */
          },
          placeholderText: {
            label: () => t('donationForm.donorNote.settings.placeholderText.label'), /* Placeholder text */
          },
          initialText: {
            label: () => t('donationForm.donorNote.settings.initialText.label'), /* Show initial text */
            placeholder: () => t('donationForm.donorNote.settings.initialText.placeholder'), /* Placeholder */
            none: () => t('donationForm.donorNote.settings.initialText.none'), /* None */
          },
          showTitle: {
            toggle: {
              label: () => t('donationForm.donorNote.settings.showTitle.toggle.label'), /* Show title */
            },
          },
          panelHeader: () => t('donationForm.donorNote.settings.panelHeader'), /* Donor Note Settings */
          characterLimit: {
            header: () => t('donationForm.donorNote.settings.characterLimit.header'), /* Character limit */
            label: () => t('donationForm.donorNote.settings.characterLimit.label'), /* Maximum characters */
          },
        },
        displayName: () => t('donationForm.donorNote.displayName'), /* Donor Note */
        gfpp: {
          settings: () => t('donationForm.donorNote.gfpp.settings'), /* Settings */
        },
        inputField: {
          displayName: () => t('donationForm.donorNote.inputField.displayName'), /* Donor Note Input Field */
          gfpp: {
            design: () => t('donationForm.donorNote.inputField.gfpp.design'), /* Design Input Field */
          },
        },
        title: {
          displayName: () => t('donationForm.donorNote.title.displayName'), /* Title */
        },
      },
      frequencyTitle: {
        displayName: () => t('donationForm.frequencyTitle.displayName'), /* Frequency Title */
      },
      amountButtons: {
        displayName: () => t('donationForm.amountButtons.displayName'), /* Amount Buttons */
      },
      otherAmountButton: {
        displayName: () => t('donationForm.otherAmountButton.displayName'), /* Other Amount Button */
      },
      amountTitle: {
        displayName: () => t('donationForm.amountTitle.displayName'), /* Amount Title */
      },
      noteTitle: {
        displayName: () => t('donationForm.noteTitle.displayName'), /* Donor Note Title */
      },
      otherAmountField: {
        displayName: () => t('donationForm.otherAmountField.displayName'), /* Amount Input Field */
        gfpp: {
          design: () => t('donationForm.otherAmountField.gfpp.design'), /* Design Input Field */
        },
      },
      frequencyButtons: {
        displayName: () => t('donationForm.frequencyButtons.displayName'), /* Frequency Buttons */
      },
      noteField: {
        displayName: () => t('donationForm.noteField.displayName'), /* Donor Note Input Field */
        characterCounter: {
          displayName: () => t('donationForm.noteField.characterCounter.displayName'), /* Character Counter */
        },
      },
      amountOption: {
        selected: {
          displayName: () => t('donationForm.amountOption.selected.displayName'), /* Amount box (Selected) */
          gfpp: {
            design: () => t('donationForm.amountOption.selected.gfpp.design'), /* Design Selected State */
          },
        },
        regular: {
          displayName: () => t('donationForm.amountOption.regular.displayName'), /* Amount box (Regular) */
          gfpp: {
            design: () => t('donationForm.amountOption.regular.gfpp.design'), /* Design Regular State */
          },
        },
      },
      frequencyOption: {
        regular: {
          displayName: () => t('donationForm.frequencyOption.regular.displayName'), /* Frequency box (Regular) */
          gfpp: {
            design: () => t('donationForm.frequencyOption.regular.gfpp.design'), /* Design Regular State */
          },
        },
        selected: {
          displayName: () => t('donationForm.frequencyOption.selected.displayName'), /* Frequency box (Selected) */
          gfpp: {
            design: () => t('donationForm.frequencyOption.selected.gfpp.design'), /* Design Selected State */
          },
        },
      },
      amountText: {
        regular: {
          displayName: () => t('donationForm.amountText.regular.displayName'), /* Amount number (Regular) */
          gfpp: {
            design: () => t('donationForm.amountText.regular.gfpp.design'), /* Design Regular State */
          },
        },
        selected: {
          displayName: () => t('donationForm.amountText.selected.displayName'), /* Amount number (Selected) */
          gfpp: {
            design: () => t('donationForm.amountText.selected.gfpp.design'), /* Design Selected State */
          },
        },
      },
      frequencyText: {
        selected: {
          displayName: () => t('donationForm.frequencyText.selected.displayName'), /* Frequency text (Selected) */
          gfpp: {
            design: () => t('donationForm.frequencyText.selected.gfpp.design'), /* Design Selected State */
          },
        },
        regular: {
          displayName: () => t('donationForm.frequencyText.regular.displayName'), /* Frequency text (Regular) */
          gfpp: {
            design: () => t('donationForm.frequencyText.regular.gfpp.design'), /* Design Regular State */
          },
        },
      },
      gfpp: {
        learnMore: () => t('donationForm.gfpp.learnMore'), /* Learn More */
      },
    },
    wixDonations: {
      installationPopup: {
        loader: {
          header: () => t('wixDonations.installationPopup.loader.header'), /* Installing Wix Donations on your site */
          step2: () => t('wixDonations.installationPopup.loader.step2'), /* Adding everything you need to collect donations... */
          done: () => t('wixDonations.installationPopup.loader.done'), /* Wix Donations is now ready to use */
        },
      },
      addPanel: {
        formWidget: {
          name: () => t('wixDonations.addPanel.formWidget.name'), /* Donation Form */
        },
        appName: () => t('wixDonations.addPanel.appName'), /* Wix Donations */
      },
    },
    thankYouPage: {
      duration: {
        weekly: () => t('thankYouPage.duration.weekly'), /* weekly */
        oneTime: () => t('thankYouPage.duration.oneTime'), /* generous */
        yearly: () => t('thankYouPage.duration.yearly'), /* yearly */
        monthly: () => t('thankYouPage.duration.monthly'), /* monthly */
      },
      line1: (data: Record<'frequency' | 'amount', unknown>) => t('thankYouPage.line1', data), /* We are so grateful for your {frequency} donation of {amount}. */
      title: (data: Record<'firstName' | 'lastName', unknown>) => t('thankYouPage.title', data), /* Thank you, {firstName} {lastName} */
      line2: (data: Record<'num', unknown>) => t('thankYouPage.line2', data), /* Your donation number is #{num}. You’ll receive a confirmation email soon. */
      pageName: () => t('thankYouPage.pageName'), /* Thank You Page */
      thankYouPageWidget: {
        displayName: () => t('thankYouPage.thankYouPageWidget.displayName'), /* Thank You Page */
        direction: {
          label: () => t('thankYouPage.thankYouPageWidget.direction.label'), /* Text direction */
          tooltip: {
            text: () => t('thankYouPage.thankYouPageWidget.direction.tooltip.text'), /* Choose the right to left direction if your site is in a language like Arabic or Hebrew. If you have a  multilingual site, this will change the direction for all of your languages. */
          },
        },
        ltr: {
          option: () => t('thankYouPage.thankYouPageWidget.ltr.option'), /* Left to right */
        },
        rtl: {
          option: () => t('thankYouPage.thankYouPageWidget.rtl.option'), /* Right to left */
        },
        layout: {
          panelHeader: () => t('thankYouPage.thankYouPageWidget.layout.panelHeader'), /* Thank You Page Layout */
        },
      },
      donorName: {
        editor: {
          dummyText: () => t('thankYouPage.donorName.editor.dummyText'), /* Donor Name */
        },
      },
      frequency: {
        editor: {
          dummyText: () => t('thankYouPage.frequency.editor.dummyText'), /* generous */
        },
      },
      recurringDonation: {
        line2: () => t('thankYouPage.recurringDonation.line2'), /* You'll receive a confirmation email soon. */
      },
      pendingPayment: {
        line2: () => t('thankYouPage.pendingPayment.line2'), /* You'll receive an email once your payment is confirmed. */
      },
    },
    myBusinessPanel: {
      manage: {
        addNewForm: {
          button: () => t('myBusinessPanel.manage.addNewForm.button'), /* Add Donation Form */
        },
        manageCamapigns: {
          button: () => t('myBusinessPanel.manage.manageCamapigns.button'), /* Manage Campaigns */
        },
      },
      upgradeBannertext: () => t('myBusinessPanel.upgradeBannertext'), /* To accept payments for donations, */
      upgradeBannerlink: () => t('myBusinessPanel.upgradeBannerlink'), /* upgrade now. */
    },
    donations: {
      widget: {
        progressBar: {
          daysLeft: (data: Record<'numDays' | '0 days left' | '1 day left!' | '# days left!', unknown>) => t('donations.widget.progressBar.daysLeft', data), /* {numDays, plural,  =0 {0 days left} one {1 day left!} other {# days left!}} */
          amountRaised: {
            $value: (data: Record<'numRaised', unknown>) => t('donations.widget.progressBar.amountRaised', data), /* {numRaised} raised */
            displayName: () => t('donations.widget.progressBar.amountRaised.displayName'), /* Amount raised */
          },
          empty: {
            title: () => t('donations.widget.progressBar.empty.title'), /* Campaign goal progress */
            subtitle: () => t('donations.widget.progressBar.empty.subtitle'), /* Choose a campaign to display goal */
          },
          donationsAmount: (data: Record<'numDonations' | '1 donation' | '# donations', unknown>) => t('donations.widget.progressBar.donationsAmount', data), /* {numDonations, plural, one {1 donation}  other {# donations}}. */
          amountGoal: (data: Record<'numGoal', unknown>) => t('donations.widget.progressBar.amountGoal', data), /* {numGoal} goal */
          hoursLeft: (data: Record<'numHours' | '1 hour left!' | '# hours left!', unknown>) => t('donations.widget.progressBar.hoursLeft', data), /* {numHours, plural, one {1 hour left!} other {# hours left!}} */
          reached: {
            title: () => t('donations.widget.progressBar.reached.title'), /* Goal reached! */
            subtitle: () => t('donations.widget.progressBar.reached.subtitle'), /* Goal reached! */
            note: () => t('donations.widget.progressBar.reached.note'), /* This campaign has reached its fundraising goal and is no longer accepting donations. We appreciate your support! */
            cta: () => t('donations.widget.progressBar.reached.cta'), /* Join Our Community */
          },
          deadlinePassed: {
            title: {
              $value: () => t('donations.widget.progressBar.deadlinePassed.title'), /* Deadline passed */
              displayName: () => t('donations.widget.progressBar.deadlinePassed.title.displayName'), /* Title (deadline passed) */
            },
            note: () => t('donations.widget.progressBar.deadlinePassed.note'), /* The fundraising deadline for this campaign has passed. Thank you for your interest in donating! */
            cta: () => t('donations.widget.progressBar.deadlinePassed.cta'), /* Join Our Community */
            description: {
              displayName: () => t('donations.widget.progressBar.deadlinePassed.description.displayName'), /* Description (deadline passed) */
            },
            button: {
              displayName: () => t('donations.widget.progressBar.deadlinePassed.button.displayName'), /* Button (deadline passed) */
            },
          },
          noGoal: {
            title: () => t('donations.widget.progressBar.noGoal.title'), /* Campaign goal progress */
            subtitle: () => t('donations.widget.progressBar.noGoal.subtitle'), /* Set up your goal in the Dashboard to display it on this element.  */
          },
          dynamicDonationsAmount: (data: Record<'numDonations' | '1 donation' | '# donations', unknown>) => t('donations.widget.progressBar.dynamicDonationsAmount', data), /* {numDonations, plural, one {1 donation}  other {# donations}} */
          dynamicDaysLeft: (data: Record<'numDays' | 'No days left' | '1 day left!' | '# days left!', unknown>) => t('donations.widget.progressBar.dynamicDaysLeft', data), /* {numDays, plural,  =0 {No days left} one {1 day left!} other {# days left!}} */
          dynamicHoursLeft: (data: Record<'numHours' | '1 hour left!' | '# hours left!', unknown>) => t('donations.widget.progressBar.dynamicHoursLeft', data), /* {numHours, plural, one {1 hour left!} other {# hours left!}} */
          goalAmount: {
            displayName: () => t('donations.widget.progressBar.goalAmount.displayName'), /* Goal amount */
          },
          percentageFromGoal: {
            displayName: () => t('donations.widget.progressBar.percentageFromGoal.displayName'), /* Percentage raised */
          },
          displayName: () => t('donations.widget.progressBar.displayName'), /* Progress bar */
          deadlineCountdown: {
            displayName: () => t('donations.widget.progressBar.deadlineCountdown.displayName'), /* Deadline countdown */
          },
          goalReached: {
            description: {
              displayName: () => t('donations.widget.progressBar.goalReached.description.displayName'), /* Description (goal reached) */
            },
            button: {
              displayName: () => t('donations.widget.progressBar.goalReached.button.displayName'), /* Button (goal reached) */
            },
            title: {
              displayName: () => t('donations.widget.progressBar.goalReached.title.displayName'), /* Title (goal reached) */
            },
          },
          numDonations: {
            displayName: () => t('donations.widget.progressBar.numDonations.displayName'), /* Donations collected */
          },
          title: {
            displayName: () => t('donations.widget.progressBar.title.displayName'), /* Title */
          },
          description: {
            displayName: () => t('donations.widget.progressBar.description.displayName'), /* Description */
          },
          button: {
            displayName: () => t('donations.widget.progressBar.button.displayName'), /* Button */
          },
        },
        form: {
          donateButton: (data: Record<'freeText' | 'amount' | 'frequency', unknown>) => t('donations.widget.form.donateButton', data), /* {freeText} {amount} {frequency} */
        },
        amountsLayout: {
          header: () => t('donations.widget.amountsLayout.header'), /* Amounts Layout */
          chooseLayout: {
            dynamic: {
              note: () => t('donations.widget.amountsLayout.chooseLayout.dynamic.note'), /* Note: This layout shows impact descriptions only once donors click on an amount. It works best for long descriptions. To see how it works, Preview your site. */
              $value: () => t('donations.widget.amountsLayout.chooseLayout.dynamic'), /* Dynamic */
            },
            horizontal: () => t('donations.widget.amountsLayout.chooseLayout.horizontal'), /* Horizontal */
            label: () => t('donations.widget.amountsLayout.chooseLayout.label'), /* Choose a layout */
            vertical: () => t('donations.widget.amountsLayout.chooseLayout.vertical'), /* Vertical */
          },
        },
        progressBarElements: {
          display: {
            goalAmount: () => t('donations.widget.progressBarElements.display.goalAmount'), /* Goal amount */
            label: () => t('donations.widget.progressBarElements.display.label'), /* Choose which elements to display on the Progress Bar. */
            progressFill: () => t('donations.widget.progressBarElements.display.progressFill'), /* Progression line */
            raisedAmount: () => t('donations.widget.progressBarElements.display.raisedAmount'), /* Amount raised */
            numDonations: () => t('donations.widget.progressBarElements.display.numDonations'), /* Donations collected */
            note: () => t('donations.widget.progressBarElements.display.note'), /* Note: Once a goal is set, information remains visible in site data, even if all elements are hidden. */
            percentageFromGoal: () => t('donations.widget.progressBarElements.display.percentageFromGoal'), /* Percentage raised from goal */
            deadlineCountdown: () => t('donations.widget.progressBarElements.display.deadlineCountdown'), /* Deadline countdown */
            sectionName: () => t('donations.widget.progressBarElements.display.sectionName'), /* Progress bar */
          },
          header: () => t('donations.widget.progressBarElements.header'), /* Progress bar */
        },
        manage: {
          manageAmounts: {
            button: () => t('donations.widget.manage.manageAmounts.button'), /* Manage Amounts */
          },
          manageGoal: {
            button: () => t('donations.widget.manage.manageGoal.button'), /* Manage Goal */
          },
        },
        breadcrumbs: {
          state: {
            collectingDonations: () => t('donations.widget.breadcrumbs.state.collectingDonations'), /* Collecting donations */
            goalReached: () => t('donations.widget.breadcrumbs.state.goalReached'), /* Goal reached */
            deadlinePassed: () => t('donations.widget.breadcrumbs.state.deadlinePassed'), /* Deadline passed */
          },
        },
      },
    },
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;
